import React, { useState } from 'react';
import BottomMenuBar from './BottomMenuBar';
import EmailForm from './EmailForm';
import mekong from '../images/mekong.png';
import me from '../images/me_casual.png';

function AboutMe() {
  const [isEmailVisible, setisEmailVisible] = useState(false);
  const toggleEmailVisibility = () => {
    setisEmailVisible(!isEmailVisible);
  };

  return (
    <>
    <style>
    {`
      body, html {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
    `}
    </style>
    <div style={styles.page}>
      <div style={styles.container}>
        <h1 style={styles.heading}>About Me</h1>
        <div style={styles.content}>
          <div style={styles.imageWrapper}>
            <img 
              src={me}
              alt="Axel" 
              style={styles.image}
            />
          </div>
          
          <div style={styles.textContent}>
            <p style={styles.text}>
              Hi there! I’m Axel, a developer based in Stockholm, currently working as a consultant at HiQ. My career has mainly been spent in and around industrial projects, where I thrive on tackling complex problems and delivering effective solutions.
              I graduated from Middlebury College in 2023 with a degree in Computer Science and a minor in English Literature. At college, I studied AI, Algorithms, and Software Development — skills that I love to apply in my professional life and as a hobby.
              When I’m not working, you can find me enjoying the great outdoors, exercising, traveling, or experimenting with software like React, which is how I created this webpage! Thanks for stopping by! 
            </p>
          </div>
        </div>
      </div>
      {isEmailVisible && <EmailForm onClose={toggleEmailVisibility} />}
      <BottomMenuBar onShowBox={toggleEmailVisibility}/>
    </div>
    </>
  );
}

const styles = {
  page: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    backgroundImage: `url(${mekong})`,  
    backgroundSize: 'cover',          
    backgroundPosition: 'center',      
    backgroundRepeat: 'no-repeat',     
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    padding: '10px',
    boxSizing: 'border-box',
    zIndex: 998,
  },
  heading: {
    fontSize: '3rem',
    marginBottom: '20px',
    color: 'white',
    textAlign: 'center',
  },
  content: {
    position: 'relative',  // Needed for absolute positioning of image
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    maxWidth: '800px',
    width: '90%', 
    backgroundColor: '#fff',
    borderRadius: '15px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    paddingTop: '60px',  // Extra space to account for floating image
  },
  imageWrapper: {
    position: 'absolute',
    top: '-30px',  // Moves image above the content box
    left: '-30px',  // Moves image to the left
    width: '100px',  
    height: '100px',
    borderRadius: '50%',
    overflow: 'hidden',
    boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.15)',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  textContent: {
    textAlign: 'left',
  },
  text: {
    fontSize: '1.2rem',
    lineHeight: '1.6',
    color: '#555',
  },

  // Mobile Optimizations
  '@media (max-width: 768px)': {
    content: {
      paddingTop: '20px',  // More space for the free-floating image
    },
    imageWrapper: {
      width: '80px',
      height: '80px',
      top: '-10px',
      left: '-20px',
    },
    text: {
      fontSize: '1rem',
    },
  },
};


export default AboutMe;
