import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import CV from './components/CV';
import AboutMe from './components/AboutMe';
import BottomMenuBar from './components/BottomMenuBar';
import DesktopIcons from './components/DesktopIcons';
import MosaicGallery from './components/Mosaic';
import EmailForm from './components/EmailForm';
import background from './images/bckground_chatgpt.png';
import GeneralInfo from './components/GeneralInfo';
import './global.css'

// Main App Component
function App() {
  const [isEmailVisible, setisEmailVisible] = useState(false);
  const [isInfoVisible, setisInfoVisible] = useState(false);

  const toggleEmailVisibility = () => {
    setisEmailVisible(!isEmailVisible);
  };

  const toggleInfoVisibility = () => {
    setisInfoVisible(!isInfoVisible);
  };

  return (
    <>
      <style>
        {`
          body, html {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
          }
        `}
      </style>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexDirection: 'column',
          height: '100vh',
          width: '100vw',
          backgroundImage: `url(${background})`,  // Correctly set the background image
          backgroundSize: 'cover',           // Ensure the image covers the entire screen
          backgroundPosition: 'center',      // Center the image
          backgroundRepeat: 'no-repeat',     // Prevent the image from repeating
        }}
      >
        {isEmailVisible && <EmailForm onClose={toggleEmailVisibility} />}
        {isInfoVisible && <GeneralInfo onClose={toggleInfoVisibility} />}
        <DesktopIcons onShowEmail={toggleEmailVisibility} onShowInfo={toggleInfoVisibility} />
        <BottomMenuBar onShowBox={toggleEmailVisibility} />
      </div>
    </>
  );
}


export default App;

// Set up the Router and Routes
function Main() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about-me" element={<AboutMe />} />
        <Route path="/cv" element={<CV />} />
        <Route path="/images" element={<MosaicGallery />} />
      </Routes>
    </Router>
  );
}

// Rendering the Main component into the root div
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Main />);