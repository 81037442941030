import React from 'react';

function GeneralInfo({ onClose }) {
    return (
      <div style={styles.overlay}>
        <div style={styles.box}>
          <button onClick={onClose} style={styles.closeButton}>&times;</button>
          <h1 style={styles.title}>Welcome to my Webpage!</h1>
          
          <p style={styles.bodyText}>
            I am happy you have found your way to my personal webpage!
            My name is Axel, and I am a developer currently located in Stockholm, Sweden.<br />
            Here you can take a look at my CV, read about me, and reach out if you have questions.<br />
            <br />
            <strong>About this page:</strong><br />
            I used React to make this page. The images I have taken myself, and the background was generated with OpenAI's DALL·E.
            The "desktop icons" you see on the landing page are all from
            <a href="https://lineicons.com" style={styles.link} target="_blank" rel="noopener noreferrer">
              lineicons.com.
            </a>
            <br />
            Feel free to navigate around!
          </p>
          <button onClick={onClose} style={styles.button}>Got it!</button>
        </div>
      </div>
    );
}

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Dimmed background for better visibility
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  box: {
    width: '90%',
    maxWidth: '600px',  // Limits max width for larger screens
    maxHeight: '80vh',  // Prevents it from being too tall on small screens
    overflowY: 'auto',  // Allows scrolling if content is too long
    padding: '20px',
    backgroundColor: '#1B3244',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    textAlign: 'center',
    color: '#fff',
    position: 'relative',
  },
  title: {
    fontSize: '1.8rem',
    marginBottom: '15px',
    color: '#fff',
  },
  bodyText: {
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#fff',
    marginBottom: '15px',
  },
  link: {
    color: '#00bfff',
    textDecoration: 'none',
  },
  button: {
    padding: '12px 20px',
    fontSize: '1rem',
    borderRadius: '5px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    transition: 'background 0.3s ease-in-out',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '15px',
    background: 'transparent',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
    color: 'white',
    fontWeight: 'bold',
  },
  '@media (max-width: 600px)': {
    box: {
      width: '95%',
      padding: '15px',
    },
    title: {
      fontSize: '1.5rem',
    },
    bodyText: {
      fontSize: '0.9rem',
    },
    button: {
      fontSize: '0.9rem',
      padding: '10px',
    },
  },
};

export default GeneralInfo;
