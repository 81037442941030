import React, { useState } from 'react';
import emailjs from 'emailjs-com';

function EmailForm({ onClose }) {
  const [formData, setFormData] = useState({
    userEmail: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceID = 'service_7akoq3h';
    const templateID = 'template_3767mcp';
    const userID = 'OTGQwfF6FfMJQvvAm';

    emailjs.send(serviceID, templateID, formData, userID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Email sent successfully!');
      }, (error) => {
        console.log('FAILED...', error);
        alert('Failed to send email. Please try again.');
      });
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.container}>
        <button onClick={onClose} style={styles.closeButton}>&times;</button>
        <h2 style={styles.heading}>Send an Email</h2>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.formGroup}>
            <label>Your Email Address:</label>
            <input
              type="email"
              name="userEmail"
              value={formData.userEmail}
              onChange={handleChange}
              required
              style={styles.input}
            />
          </div>
          <div style={styles.formGroup}>
            <label>Subject:</label>
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
              style={styles.input}
            />
          </div>
          <div style={styles.formGroup}>
            <label>Message:</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              rows="5"
              style={styles.textarea}
            />
          </div>
          <button type="submit" style={styles.button}>Send Email</button>
        </form>
      </div>
    </div>
  );
}

// Updated Responsive Styles
const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Dimmed background for focus
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  container: {
    width: '90%',
    maxWidth: '450px',
    backgroundColor: '#1B3244',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    color: '#fff',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    fontSize: '1.5rem',
    textAlign: 'center',
    marginBottom: '15px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  formGroup: {
    marginBottom: '15px',
    textAlign: 'left',
  },
  input: {
    width: '100%',
    padding: '12px',
    fontSize: '1rem',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    color: '#000',
  },
  textarea: {
    width: '100%',
    padding: '12px',
    fontSize: '1rem',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
    resize: 'vertical',
    backgroundColor: '#fff',
    color: '#000',
  },
  button: {
    padding: '12px',
    fontSize: '1rem',
    borderRadius: '5px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    transition: 'background 0.3s ease-in-out',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '15px',
    background: 'transparent',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
    color: 'white',
    fontWeight: 'bold',
  },
  '@media (max-width: 600px)': {
    container: {
      width: '95%',
      padding: '15px',
    },
    input: {
      fontSize: '0.9rem',
      padding: '10px',
    },
    textarea: {
      fontSize: '0.9rem',
      padding: '10px',
    },
    button: {
      fontSize: '0.9rem',
      padding: '10px',
    },
  },
};

export default EmailForm;
